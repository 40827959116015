import React from 'react';

import { IconButton, Menu } from '@mui/material';
import NotificationItem from 'components/Items/NotificationItem';
import Icon from '@mui/material/Icon';

// GraphQL
import { graphql } from 'gql';
import { useQuery } from 'urql';
import MDBadge from 'components/MDBadge';

// Custom styles for DashboardNavbar
import { navbarIconButton } from 'components/Navbars/DashboardNavbar/styles';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NotificationsQuery = graphql(`
    query notificationsQuery {
        newOrders: ordersAggregate(where: { state: { _eq: "New" } }) {
            aggregate {
                count
            }
        }
        pastDueOrders: ordersAggregate(
            where: { shippingDueDate: { _lt: "now()" }, state: { _neq: "Shipped" } }
        ) {
            aggregate {
                count
            }
        }
    }
`);

function NotificationsMenu({
    openMenu,
    handleOpenMenu,
    handleCloseMenu,
    iconsStyle
}: {
    openMenu: any;
    handleOpenMenu: any;
    handleCloseMenu: any;
    iconsStyle: any;
}) {
    const [result, reexecuteQuery] = useQuery({ query: NotificationsQuery });
    const navigate = useNavigate();
    const { t } = useTranslation("notifications");

    const { data, fetching, error } = result;

    const notificationItems: any = [];

    function renderNoNotifications() {
        return (
            <>
                <IconButton
                    size="small"
                    color="inherit"
                    sx={navbarIconButton}
                    onClick={handleOpenMenu}
                >
                    <Icon sx={iconsStyle}>notifications</Icon>
                </IconButton>
                <Menu
                    anchorEl={openMenu}
                    anchorReference={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    open={Boolean(openMenu)}
                    onClose={handleCloseMenu}
                    sx={{ mt: 2 }}
                >
                    {}
                    <NotificationItem
                        icon={<Icon>notifications</Icon>}
                        title={t("noNewNotifications")}
                    />
                </Menu>
            </>
        );
    }

    if (fetching || error) {
        return renderNoNotifications();
    }

    // Notification processing goes here
    // ---------------------------------

    // New orders available
    const newOrdersCount = data.newOrders.aggregate.count;
    if (newOrdersCount) {
        notificationItems.push(
            <NotificationItem
                key="newOrdersNotification"
                icon={<Icon>star</Icon>}
                title={newOrdersCount === 1 ? t("newOrdersOne") : t("newOrdersMultiple", {newOrdersCount})}
                onClick={() => {
                    navigate('/orders');
                }}
            />
        );
    }

    // Past due orders
    const pastDueOrdersCount = data.pastDueOrders.aggregate.count;
    if (pastDueOrdersCount) {
        notificationItems.push(
            <NotificationItem
                key="pastDueOrdersNotification"
                icon={<Icon>warning</Icon>}
                title={pastDueOrdersCount === 1 ? t("pastDueOrdersOne") : t("pastDueOrdersMultiple", {pastDueOrdersCount})}
                onClick={() => {
                    navigate('/orders');
                }}
            />
        );
    }

    if (notificationItems.length === 0) {
        return renderNoNotifications();
    }

    return (
        <>
            <IconButton size="small" color="inherit" sx={navbarIconButton} onClick={handleOpenMenu}>
                <MDBadge badgeContent={notificationItems.length} color="error" size="xs" circular>
                    <Icon sx={iconsStyle}>notifications</Icon>
                </MDBadge>
            </IconButton>
            <Menu
                anchorEl={openMenu}
                anchorReference={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                open={Boolean(openMenu)}
                onClose={handleCloseMenu}
                sx={{ mt: 2 }}
            >
                {notificationItems}
            </Menu>
        </>
    );
}

export default NotificationsMenu;
