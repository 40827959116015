/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from 'layouts/order-list/components/IdCell';
import DefaultCell from 'layouts/order-list/components/DefaultCell';
import StatusCell from 'layouts/order-list/components/StatusCell';
import ItemsCell from 'layouts/order-list/components/ItemsCell';
import DateCell from 'layouts/order-list/components/DateCell';

const dataTableData = {
    columns: [
        {
            Header: 'orderInfo.tableHeader.productCode',
            accessor: 'product.productCode',
            Cell: ({ value }: any) => <DefaultCell value={value} />
        },
        {
            Header: 'orderInfo.tableHeader.productName',
            accessor: 'product.productName',
            Cell: ({ value }: any) => <DefaultCell value={value} />
        },
        {
            Header: 'orderInfo.tableHeader.quantity',
            accessor: 'quantity',
            Cell: ({ value }: any) => <DefaultCell value={value} />
        },
        {
            Header: 'orderInfo.tableHeader.revenue',
            accessor: 'transferPrice',
            Cell: ({ value }: any) => <DefaultCell value={value} />
        }
    ],
    rows: [] as any
};

export default dataTableData;
