/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import React from 'react';

// Material Dashboard 2 PRO React layouts
import Analytics from 'layouts/dashboard';
import Sales from 'mdpro/layouts/dashboards/sales';
import ProfileOverview from 'mdpro/layouts/pages/profile/profile-overview';
import AllProjects from 'mdpro/layouts/pages/profile/all-projects';
import NewUser from 'mdpro/layouts/pages/users/new-user';
import Settings from 'mdpro/layouts/pages/account/settings';
import Billing from 'mdpro/layouts/pages/account/billing';
import Invoice from 'mdpro/layouts/pages/account/invoice';
import Timeline from 'mdpro/layouts/pages/projects/timeline';
import PricingPage from 'mdpro/layouts/pages/pricing-page';
import Widgets from 'mdpro/layouts/pages/widgets';
import RTL from 'mdpro/layouts/pages/rtl';
import Charts from 'mdpro/layouts/pages/charts';
import Notifications from 'mdpro/layouts/pages/notifications';
import Wizard from 'mdpro/layouts/applications/wizard';
import DataTables from 'mdpro/layouts/applications/data-tables';
import Calendar from 'mdpro/layouts/applications/calendar';
import NewProduct from 'mdpro/layouts/ecommerce/products/new-product';
import EditProduct from 'mdpro/layouts/ecommerce/products/edit-product';
import ProductPage from 'mdpro/layouts/ecommerce/products/product-page';
import OrderList from 'layouts/order-list';
import OrderDetails from 'layouts/order-details';
import SignInBasic from 'layouts/login';
import SignInCover from 'mdpro/layouts/authentication/sign-in/cover';
import SignInIllustration from 'mdpro/layouts/authentication/sign-in/illustration';
import SignUpCover from 'mdpro/layouts/authentication/sign-up/cover';
import ResetCover from 'mdpro/layouts/authentication/reset-password/cover';

// Material Dashboard 2 PRO React TS components
import MDAvatar from 'components/MDAvatar';

// @mui icons
import Icon from '@mui/material/Icon';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CurrencyYenIcon from '@mui/icons-material/CurrencyYen';

// Images
import profilePicture from 'assets/images/yauji.png';

// i18n
import { useTranslation } from 'react-i18next';

const sidenavDefinition = [
    {},
    {
        type: 'collapse',
        name: '田中 太郎',
        key: 'tanaka-taro',
        icon: <MDAvatar src={profilePicture} alt="Tanaka Taro" size="sm" />,
        collapse: [
            {
                name: 'Profile',
                i18nKey: 'sideNav:profile',
                key: 'my-profile',
                route: '/pages/profile/profile-overview',
                icon: <Icon fontSize="medium">person</Icon>
            },
            {
                name: 'Settings',
                i18nKey: 'sideNav:settings',
                key: 'profile-settings',
                route: '/pages/account/settings',
                icon: <Icon fontSize="medium">settings</Icon>
            }
        ]
    },
    { type: 'divider', key: 'divider-1' },
    {
        type: 'collapse',
        i18nKey: 'sideNav:dashboard',
        key: 'dashboard',
        route: '/',
        icon: <Icon fontSize="medium">dashboard</Icon>,
        noCollapse: true
    },
    {
        type: 'collapse',
        i18nKey: 'sideNav:orders',
        key: 'orders',
        route: '/orders',
        icon: <ShoppingCartIcon fontSize="medium" />,
        component: <OrderList />,
        noCollapse: true
    },
    {
        type: 'collapse',
        i18nKey: 'sideNav:account',
        key: 'account',
        route: '/account',
        icon: <CurrencyYenIcon fontSize="medium" />,
        noCollapse: true
    },
    {
        type: 'collapse',
        i18nKey: 'sideNav:inventory',
        key: 'inventory',
        route: '/inventory',
        icon: <Icon fontSize="medium">warehouse</Icon>,
        noCollapse: true
    },
    {
        route: '/orders/:orderId',
        key: 'order'
    },
    { type: 'divider', key: 'divider-2' },
    {
        type: 'collapse',
        i18nKey: 'sideNav:logout',
        key: 'logout',
        route: '/logout',
        icon: <Icon fontSize="medium">logout</Icon>,
        noCollapse: true
    }
];

export default sidenavDefinition;
