/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// GraphQL
import { graphql } from 'gql';
import { useMutation, useQuery } from 'urql';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import Footer from 'components/Footer';
import DataTable from 'components/Tables/DataTable';

// Data
import dataTableData from 'layouts/order-list/data/dataTableData';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

// Snackbar
import MDAlert from 'components/MDAlert';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';

const AcceptAllOrders = graphql(`
    mutation acceptAllOrders(
        $oldState: String!
        $newState: String!
        $acceptanceTimestamp: timestamptz!
    ) {
        updateOrdersMany(
            updates: {
                where: { state: { _eq: $oldState } }
                _set: { state: $newState, acceptanceTimestamp: $acceptanceTimestamp }
            }
        ) {
            affectedRows
            returning {
                id
            }
        }
    }
`);

const OrdersQuery = graphql(`
    query GetOrders {
        orders {
            id
            orderTimestamp
            orderNumber
            state
            shippingDueDate
            totalTransferValue
            lineItems {
                id
                product {
                    id
                    productCode
                    productName
                }
                quantity
            }
            org {
                id
                country {
                    id
                    holidays {
                        id
                        date
                    }
                }
            }
        }
        ordersAggregate(where: { state: { _eq: "New" } }) {
            aggregate {
                count
            }
        }
    }
`);

function OrderList(): JSX.Element {
    const { t } = useTranslation('orderList');
    const [menu, setMenu] = useState(null);
    const [result, reexecuteQuery] = useQuery({
        query: OrdersQuery
    });
    const [acceptAllOrdersResult, acceptAllOrders] = useMutation(AcceptAllOrders);

    const openMenu = (event: any) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const { data, fetching, error } = result;

    if (fetching) {
        return <LoadingSpinner />;
    }

    const dtData = cloneDeep(dataTableData);

    dtData.rows = data.orders;

    let newOrdersCount = 0;
    data.orders.forEach((order) => {
        if (order.state === 'New') newOrdersCount += 1;
    });

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={closeMenu}>{t("filtersDropdown.statusNew")}</MenuItem>
            <MenuItem onClick={closeMenu}>{t("filtersDropdown.statusAccepted")}</MenuItem>
            <MenuItem onClick={closeMenu}>{t("filtersDropdown.statusShipped")}</MenuItem>
            <MenuItem onClick={closeMenu}>{t("filtersDropdown.statusCancelled")}</MenuItem>
            <MenuItem onClick={closeMenu}>{t("filtersDropdown.statusReturned")}</MenuItem>
            <Divider sx={{ margin: '0.5rem 0' }} />
            <MenuItem onClick={closeMenu}>
                <MDTypography variant="button" color="error" fontWeight="regular">
                    {t("filtersDropdown.removeFilter")}
                </MDTypography>
            </MenuItem>
        </Menu>
    );
    
    return (
        <DashboardLayout>
            <DashboardNavbar />
            {newOrdersCount > 0 && (
                <MDAlert color="warning" dismissible>
                    {newOrdersCount === 1 ? t("alerts.newOrdersOne") : t("alerts.newOrdersMultiple", {newOrdersCount}) }
                </MDAlert>
            )}
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDButton
                        variant="gradient"
                        color="primary"
                        disabled={newOrdersCount === 0}
                        sx={{ mr: 1 }}
                        onClick={() => {
                            const variables = {
                                oldState: 'New',
                                newState: 'Accepted',
                                acceptanceTimestamp: dayjs().toISOString()
                            };
                            acceptAllOrders(variables);
                        }}
                    >
                        {t("acceptAll")}
                    </MDButton>
                    <MDBox display="flex">
                        <MDButton
                            variant={menu ? 'contained' : 'outlined'}
                            color="dark"
                            onClick={openMenu}
                        >
                            {t("filters")}&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                            <MDButton variant="outlined" color="dark">
                                <Icon>description</Icon>
                                &nbsp;{t("exportCSV")}
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Card>
                    <DataTable table={dtData} entriesPerPage={false} canSearch />
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default OrderList;
