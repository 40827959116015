/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useTransition } from 'react';

// @mui material components
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS Base Styles
import typography from 'assets/theme/base/typography';
import { useTranslation } from 'react-i18next';

// Declaring props types for Footer
interface Props {
    company?: {
        href: string;
        name: string;
    };
    links?: {
        href: string;
        name: string;
        i18nKey?: string;
    }[];
    [key: string]: any;
}

function Footer({ company, links }: Props): JSX.Element {
    const { t } = useTranslation('footer');
    const { href, name } = company;
    const { size } = typography;

    const renderLinks = () =>
        links.map((link) => (
            <MDBox key={link.name} component="li" px={2} lineHeight={1}>
                <Link href={link.href} target="_blank">
                    <MDTypography variant="button" fontWeight="regular" color="text">
                        {link.i18nKey ? t(link.i18nKey) : link.name}
                    </MDTypography>
                </Link>
            </MDBox>
        ));

    return (
        <MDBox
            width="100%"
            display="flex"
            flexDirection={{ xs: 'column', lg: 'row' }}
            justifyContent="space-between"
            alignItems="center"
            px={1.5}
        >
            <MDBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                color="text"
                fontSize={size.sm}
                px={1.5}
            >
                &copy; {new Date().getFullYear()}, Artisano Inc.
            </MDBox>
            <MDBox
                component="ul"
                sx={({ breakpoints }) => ({
                    display: 'flex',
                    flexWrap: 'wrap',
                    alignItems: 'center',
                    justifyContent: 'center',
                    listStyle: 'none',
                    mt: 3,
                    mb: 0,
                    p: 0,

                    [breakpoints.up('lg')]: {
                        mt: 0
                    }
                })}
            >
                {renderLinks()}
            </MDBox>
        </MDBox>
    );
}

// Declaring default props for Footer
Footer.defaultProps = {
    company: { href: 'https://www.artisanoshop.com/', name: 'Artisano' },
    links: [
        { href: 'https://www.artisanoshop.com/', name: 'Artisano' },
        {
            href: 'https://www.artisanoshop.com/license',
            name: 'Terms & Conditions',
            i18nKey: 'termsAndConditions'
        },
        {
            href: 'https://www.artisanoshop.com/license',
            name: 'Privacy Policy',
            i18nKey: 'privacyPolicy'
        }
    ]
};

export default Footer;
