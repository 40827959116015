/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Sales dashboard components
import ProductCell from 'layouts/dashboard/components/ProductCell';
import RefundsCell from 'layouts/dashboard/components/RefundsCell';
import DefaultCell from 'layouts/dashboard/components/DefaultCell';

// Images
import nikeV22 from 'assets/images/ecommerce/bugdaigaku.jpg';
import businessKit from 'assets/images/ecommerce/shinya.jpg';

const dataTableData = {
    columns: [
        { accessor: 'product', i18nKey: 'dashboard:topSellingProducts.product'},
        { accessor: 'unitSales', align: 'center', i18nKey: 'dashboard:topSellingProducts.unitSales' },
        { accessor: 'revenue', align: 'center', i18nKey: 'dashboard:topSellingProducts.revenue'},
        { accessor: 'refunds', align: 'center', i18nKey: 'dashboard:topSellingProducts.trendMoM' }
    ],

    rows: [
        {
            product: <ProductCell image={nikeV22} name="ヒューマンバグ大学" orders={9} />,
            unitSales: <DefaultCell>307</DefaultCell>,
            revenue: <DefaultCell>¥5,231,992</DefaultCell>,
            refunds: (
                <RefundsCell value="13%" icon={{ color: 'success', name: 'keyboard_arrow_up' }} />
            )
        },
        {
            product: <ProductCell image={businessKit} name="深夜食堂" orders={8} />,
            unitSales: <DefaultCell>181</DefaultCell>,
            revenue: <DefaultCell>¥2,491,096</DefaultCell>,
            refunds: (
                <RefundsCell value="4%" icon={{ color: 'error', name: 'keyboard_arrow_down' }} />
            )
        }
    ]
};

export default dataTableData;
