/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import Icon from '@mui/material/Icon';
import Card from '@mui/material/Card';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import Footer from 'components/Footer';
import ReportsBarChart from 'components/Charts/BarCharts/ReportsBarChart';
import ReportsLineChart from 'components/Charts/LineCharts/ReportsLineChart';
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard';
import DataTable from 'components/Tables/DataTable';

// Anaytics dashboard components
import SalesByCountry from 'layouts/dashboard/components/SalesByCountry';
import dataTableData from 'layouts/dashboard/data/dataTableData';

// Data
import reportsBarChartData from 'layouts/dashboard/data/reportsBarChartData';
import reportsLineChartData from 'layouts/dashboard/data/reportsLineChartData';

// i18n
import { useTranslation, Trans } from 'react-i18next';

function Dashboard(): JSX.Element {
    const { sales, tasks } = reportsLineChartData;
    const { i18n, t } = useTranslation("dashboard");

    // Action buttons for the BookingCard
    const actionButtons = (
        <>
            <Tooltip title="Refresh" placement="bottom">
                <MDTypography
                    variant="body1"
                    color="primary"
                    lineHeight={1}
                    sx={{ cursor: 'pointer', mx: 3 }}
                >
                    <Icon color="inherit">refresh</Icon>
                </MDTypography>
            </Tooltip>
            <Tooltip title="Edit" placement="bottom">
                <MDTypography
                    variant="body1"
                    color="info"
                    lineHeight={1}
                    sx={{ cursor: 'pointer', mx: 3 }}
                >
                    <Icon color="inherit">edit</Icon>
                </MDTypography>
            </Tooltip>
        </>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox py={3}>
                <MDBox mt={1.5}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="warning"
                                    icon="grade"
                                    title={t('statisticsCards.newOrders')}
                                    count={5}
                                    dataCaption={{
                                        color: 'success',
                                        amount: "55",
                                        i18nKey: 'dashboard:statisticsCards.newOrdersVsLastWeekIncrease'
                                    }}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    icon="autorenew"
                                    title={t('statisticsCards.inProgress')}
                                    count="12"
                                    dataCaption={{
                                        color: 'success',
                                        amount: "180,030",
                                        i18nKey: 'dashboard:statisticsCards.totalOrderValueIncrease'
                                    }}
                                    reverse={i18n.language === 'ja-JP'}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="primary"
                                    icon="running_with_errors"
                                    title={t('statisticsCards.pastDue')}
                                    count="0"
                                    dataCaption={{
                                        color: 'success',
                                        amount: "1.5",
                                        i18nKey: 'dashboard:statisticsCards.averageShipTime'
                                    }}
                                    
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={3}>
                            <MDBox mb={1.5}>
                                <ComplexStatisticsCard
                                    color="dark"
                                    icon="account_balance"
                                    title={t('statisticsCards.accountBalance')}
                                    count="¥1,090,422"
                                    dataCaption={{
                                        color: 'success',
                                        amount: "122,865",
                                        i18nKey: 'dashboard:statisticsCards.balanceChangeThisWeekIncrease'
                                    }}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <Grid container mt={3}>
                    <SalesByCountry />
                </Grid>
                <MDBox mt={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsBarChart
                                    color="info"
                                    title={t('charts.ordersThisWeek')}
                                    description={
                                        <Trans i18nKey="dashboard:charts.ordersChangeWeekOverWeek" values={{changeAmount: "+15%"}}>
                                            <MDTypography
                                            component="span"
                                            variant="button"
                                            fontWeight="bold"
                                            color="success"
                                            />
                                        </Trans>
                                    }
                                    date={t('charts.justUpdated')}
                                    chart={reportsBarChartData}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="success"
                                    title={t('charts.monthlySales')}
                                    description={
                                        <Trans i18nKey="dashboard:charts.salesChangeMonthOverMonth" values={{changeAmount: "+24%"}}>
                                            <MDTypography
                                            component="span"
                                            variant="button"
                                            fontWeight="bold"
                                            color="success"
                                            />
                                        </Trans>
                                    }
                                    date={t('charts.justUpdated')}
                                    chart={sales}
                                />
                            </MDBox>
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <MDBox mb={3}>
                                <ReportsLineChart
                                    color="dark"
                                    title={t('charts.monthlyRevenue')}
                                    description={
                                        <Trans i18nKey="dashboard:charts.revenueChangeMonthOverMonth" values={{changeAmount: "+18%"}}>
                                            <MDTypography
                                            component="span"
                                            variant="button"
                                            fontWeight="bold"
                                            color="success"
                                            />
                                        </Trans>
                                    }
                                    date={t('charts.justUpdated')}
                                    chart={tasks}
                                />
                            </MDBox>
                        </Grid>
                    </Grid>
                </MDBox>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox pt={3} px={3}>
                                <MDTypography variant="h6" fontWeight="medium">
                                    {t('topSellingProducts.topSellingProducts')}
                                </MDTypography>
                            </MDBox>
                            <MDBox py={1}>
                                <DataTable
                                    table={dataTableData}
                                    entriesPerPage={false}
                                    showTotalEntries={false}
                                    isSorted={false}
                                />
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Dashboard;
