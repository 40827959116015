/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// GraphQL
import { GetOrdersQuery } from 'gql/graphql';

import MDTypography from 'components/MDTypography';

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from '../components/IdCell';
import DefaultCell from '../components/DefaultCell';
import DescriptionCell from '../components/DescriptionCell';
import DateCell from '../components/DateCell';

const dataTableData = {
    columns: [
        {
            i18nKey: 'account:tableHeader.date',
            accessor: 'orderTimestamp',
            Cell: ({ row, value }: any) => <DateCell timestamp={value} row={row.original} />
        },
        {
            i18nKey: 'account:tableHeader.description',
            accessor: 'description',

        },
        {
            i18nKey: 'account:tableHeader.amount',
            accessor: 'amount',
            align: 'right'
        },
        {
            i18nKey: 'account:tableHeader.fees',
            accessor: 'fees',
            align: 'right'
        },
        {
            i18nKey: 'account:tableHeader.net',
            accessor: 'net',
            align: 'right'
        },
        {
            i18nKey: 'account:tableHeader.totalFunds',
            accessor: 'totalFunds',
            align: 'right'
        },
        {
            i18nKey: 'account:tableHeader.details',
            accessor: 'details',
            align: 'right'
        }
    ],
    rows: [
        {
            timestamp: "2019-09-07",
            description: <DescriptionCell icon="shopping_cart" color="success" title="Order 225" description="A-002 三徳包丁 x1" />,
            amount: <MDTypography variant="caption" fontWeight="regular" color="success">JPY 14,200</MDTypography>,
            fees: <MDTypography variant="caption" fontWeight="regular" color="error">-JPY 2,100</MDTypography>,
            net: <MDTypography variant="caption" fontWeight="medium" color="success">JPY 11,100</MDTypography>,
            totalFunds: <MDTypography variant="caption" fontWeight="regular">JPY 1,111,622</MDTypography>,
            details: <MDTypography variant="caption" fontWeight="regular" color="info">View</MDTypography>
        },
        {
            timestamp: "2019-09-07",
            description: <DescriptionCell icon="account_balance" color="info" title="Payout 101" description="JPY 1,000,000 to bank account ending in 4342" />,
            amount: <MDTypography variant="caption" fontWeight="regular" color="error">-JPY 1,000,000</MDTypography>,
            fees: <MDTypography variant="caption" fontWeight="regular" color="error">-JPY 1,000</MDTypography>,
            net: <MDTypography variant="caption" fontWeight="medium" color="error">-JPY 1,001,000</MDTypography>,
            totalFunds: <MDTypography variant="caption" fontWeight="regular">JPY 1,100,522</MDTypography>,
            details: <MDTypography variant="caption" fontWeight="regular" color="info">View</MDTypography>
        },
        {
            timestamp: "2019-09-07",
            description: <DescriptionCell icon="shopping_cart" color="success" title="Order 224" description="A-001 三徳包丁 x1" />,
            amount: <MDTypography variant="caption" fontWeight="regular" color="success">JPY 14,200</MDTypography>,
            fees: <MDTypography variant="caption" fontWeight="regular" color="error">-JPY 2,100</MDTypography>,
            net: <MDTypography variant="caption" fontWeight="medium" color="success">JPY 11,100</MDTypography>,
            totalFunds: <MDTypography variant="caption" fontWeight="regular">JPY 2,101,522</MDTypography>,
            details: <MDTypography variant="caption" fontWeight="regular" color="info">View</MDTypography>
        },
        {
            timestamp: "2019-09-07",
            description: <DescriptionCell icon="shopping_cart" color="success" title="Order 223" description="A-001 三徳包丁 x1" />,
            amount: <MDTypography variant="caption" fontWeight="regular" color="success">JPY 14,200</MDTypography>,
            fees: <MDTypography variant="caption" fontWeight="regular" color="error">-JPY 2,100</MDTypography>,
            net: <MDTypography variant="caption" fontWeight="medium" color="success">JPY 11,100</MDTypography>,
            totalFunds: <MDTypography variant="caption" fontWeight="regular">JPY 2,090,422</MDTypography>,
            details: <MDTypography variant="caption" fontWeight="regular" color="info">View</MDTypography>
        },
        
    ]
};

export default dataTableData;
