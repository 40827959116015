/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from 'react';

// GraphQL
import { graphql } from 'gql';
import { useMutation, useQuery } from 'urql';

// @mui material components
import Card from '@mui/material/Card';
import Icon from '@mui/material/Icon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import Footer from 'components/Footer';
import DataTable from 'components/Tables/DataTable';

// Data
import dataTableData from 'layouts/account/data/dataTableData';
import LoadingSpinner from 'components/LoadingSpinner';
import { useTranslation } from 'react-i18next';

// Snackbar
import MDAlert from 'components/MDAlert';
import dayjs from 'dayjs';

import cloneDeep from 'lodash/cloneDeep';
import { Grid } from '@mui/material';
import ComplexStatisticsCard from 'components/Cards/StatisticsCards/ComplexStatisticsCard';

function Account(): JSX.Element {
    const { t } = useTranslation('account');
    const [menu, setMenu] = useState(null);

    const openMenu = (event: any) => setMenu(event.currentTarget);
    const closeMenu = () => setMenu(null);

    const renderMenu = (
        <Menu
            anchorEl={menu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            open={Boolean(menu)}
            onClose={closeMenu}
            keepMounted
        >
            <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
            <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
            <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
            <Divider sx={{ margin: '0.5rem 0' }} />
            <MenuItem onClick={closeMenu}>
                <MDTypography variant="button" color="error" fontWeight="regular">
                    Remove Filter
                </MDTypography>
            </MenuItem>
        </Menu>
    );

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3} my={2}>
                <Grid item xs={12} md={6} lg={3}>
                    <MDBox mb={1.5}>
                        <ComplexStatisticsCard
                            color="info"
                            icon="account_balance"
                            title={t("statisticsCards.balance")}
                            count="¥1,111,622"
                            dataCaption={{
                                color: 'success',
                                amount: "1,100,522",
                                i18nKey: 'account:statisticsCards.availableForTransfer'
                            }}
                        />
                    </MDBox>
                </Grid>
            </Grid>
            <MDBox my={3}>
                <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
                    <MDButton
                        variant="gradient"
                        color="primary"
                        sx={{ mr: 1 }}
                        onClick={() => {
                        }}
                    >
                        Transfer Funds
                    </MDButton>
                    <MDBox display="flex">
                        <MDButton
                            variant={menu ? 'contained' : 'outlined'}
                            color="dark"
                            onClick={openMenu}
                        >
                            filters&nbsp;
                            <Icon>keyboard_arrow_down</Icon>
                        </MDButton>
                        {renderMenu}
                        <MDBox ml={1}>
                            <MDButton variant="outlined" color="dark">
                                <Icon>description</Icon>
                                &nbsp;export csv
                            </MDButton>
                        </MDBox>
                    </MDBox>
                </MDBox>
                <Card>
                    <DataTable table={dataTableData} entriesPerPage={false} canSearch isSorted={false} />
                </Card>
            </MDBox>
            <Footer />
        </DashboardLayout>
    );
}

export default Account;
