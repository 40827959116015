import React from 'react';

// Material Dashboard 2 PRO React TS components
import MDTypography from 'components/MDTypography';
import { GetOrdersQuery } from 'gql/graphql';

import Stack from '@mui/material/Stack';

// Declaring props types for DefaultCell
interface Props {
    items: GetOrdersQuery['orders'][0]['lineItems'];
}

function getItems(items: GetOrdersQuery['orders'][0]['lineItems']) {}

function ItemsCell({ items }: Props): JSX.Element {
    return (
        <Stack spacing={1}>
            {items.map((item) => (
                <MDTypography variant="caption" fontWeight="medium" color="text" key={item.id}>
                    <MDTypography variant="caption" fontWeight="regular" color="secondary">
                        {item.product.productCode}&nbsp;&nbsp;
                    </MDTypography>
                    {item.product.productName}
                    {item.quantity && (
                        <MDTypography variant="caption" fontWeight="medium" color="secondary">
                            &nbsp;&nbsp;x{item.quantity}
                        </MDTypography>
                    )}
                </MDTypography>
            ))}
        </Stack>
    );
}

// Declaring default props for DefaultCell
ItemsCell.defaultProps = {};

export default ItemsCell;
