/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// GraphQL
import { GetOrdersQuery } from 'gql/graphql';

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from 'layouts/order-list/components/IdCell';
import DefaultCell from 'layouts/order-list/components/DefaultCell';
import StatusCell from 'layouts/order-list/components/StatusCell';
import ItemsCell from '../components/ItemsCell';
import DateCell from '../components/DateCell';

const dataTableData = {
    columns: [
        {
            i18nKey: 'orderList:tableHeader.id',
            accessor: 'orderNumber',
            Cell: ({ value }: any) => <IdCell id={value} />
        },
        {
            i18nKey: 'orderList:tableHeader.date',
            accessor: 'orderTimestamp',
            Cell: ({ row, value }: any) => <DateCell timestamp={value} row={row.original} />
        },
        {
            i18nKey: 'orderList:tableHeader.status',
            accessor: 'state',
            Cell: ({ value }: any) => {
                let status;
                switch (value) {
                    case 'New':
                        status = <StatusCell icon="grade" color="warning" status="New" />;
                        break;
                    case 'Accepted':
                        status = <StatusCell icon="autorenew" color="info" status="In Progress" />;
                        break;
                    case 'Shipped':
                        status = (
                            <StatusCell icon="local_shipping" color="success" status="Shipped" />
                        );
                        break;
                    default:
                        status = <StatusCell icon="close" color="error" status="Unknown" />;
                        break;
                }
                return status;
            }
        },
        {
            i18nKey: 'orderList:tableHeader.items',
            accessor: 'lineItems',
            Cell: ({ value }: { value: GetOrdersQuery['orders'][0]['lineItems'] }) => (
                <ItemsCell items={value} />
            )
        },
        {
            i18nKey: 'orderList:tableHeader.revenue',
            accessor: 'totalTransferValue',
            Cell: ({ value }: any) => <DefaultCell value={value.toLocaleString('ja-JP')} />
        }
    ],
    rows: [] as GetOrdersQuery['orders']
};

export default dataTableData;
