/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @react-jvectormap components
import { VectorMap } from '@react-jvectormap/core';
import { worldMerc } from '@react-jvectormap/world';

// @mui material components
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import SalesTable from 'components/Tables/SalesTable';

// Data
import salesTableData from 'layouts/dashboard/components/SalesByCountry/data/salesTableData';

// i18n
import { useTranslation } from 'react-i18next';

function SalesByCountry(): JSX.Element {
    const { t } = useTranslation("dashboard", { keyPrefix: "salesByCountry"});

    return (
        <Card sx={{ width: '100%' }}>
            <MDBox display="flex">
                <MDBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    variant="gradient"
                    bgColor="success"
                    color="white"
                    shadow="md"
                    borderRadius="xl"
                    ml={3}
                    mt={-2}
                >
                    <Icon fontSize="medium" color="inherit">
                        language
                    </Icon>
                </MDBox>
                <MDTypography variant="h6" sx={{ mt: 2, mb: 1, ml: 2 }}>
                    {t('salesByCountryYTD')}
                </MDTypography>
            </MDBox>
            <MDBox p={2}>
                <Grid container>
                    <Grid item xs={12} md={7} lg={6}>
                        <SalesTable rows={salesTableData} shadow={false} />
                    </Grid>
                    <Grid item xs={12} md={5} lg={6} sx={{ mt: { xs: 5, lg: 0 } }}>
                        <VectorMap
                            map={worldMerc}
                            zoomOnScroll={false}
                            zoomButtons={false}
                            markersSelectable={false}
                            backgroundColor="transparent"
                            selectedMarkers={['1', '3']}
                            markers={[
                                {
                                    name: t('unitedStates'),
                                    latLng: [40.0, -100.00437720027804]
                                },
                                {
                                    name: t('germany'),
                                    latLng: [51.17661451970939, 10.97947735117339]
                                },
                                {
                                    name: t('greatBritain'),
                                    latLng: [50, 0]
                                },
                                {
                                    name: t('australia'),
                                    latLng: [-25.318222797104276, 135.815647771716]
                                }
                            ]}
                            regionStyle={{
                                initial: {
                                    fill: '#dee2e7',
                                    'fill-opacity': 1,
                                    stroke: 'none',
                                    'stroke-width': 0,
                                    'stroke-opacity': 0
                                }
                            }}
                            markerStyle={{
                                initial: {
                                    fill: '#e91e63',
                                    stroke: '#ffffff',
                                    'stroke-width': 5,
                                    'stroke-opacity': 0.5,
                                    r: 7
                                },
                                hover: {
                                    fill: 'E91E63',
                                    stroke: '#ffffff',
                                    'stroke-width': 5,
                                    'stroke-opacity': 0.5
                                },
                                selected: {
                                    fill: 'E91E63',
                                    stroke: '#ffffff',
                                    'stroke-width': 5,
                                    'stroke-opacity': 0.5
                                }
                            }}
                            style={{
                                marginTop: '-1.5rem'
                            }}
                            onRegionTipShow={() => false}
                            onMarkerTipShow={() => false}
                        />
                    </Grid>
                </Grid>
            </MDBox>
        </Card>
    );
}

export default SalesByCountry;
