/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Checkbox from '@mui/material/Checkbox';
import Icon from '@mui/material/Icon';
import { Link } from 'react-router-dom';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Declaring props types for IdCell
interface Props {
    id: string;
}

function IdCell({ id }: Props): JSX.Element {
    return (
        <MDBox display="flex" alignItems="center">
            <MDBox ml={1}>
                <MDTypography variant="caption" fontWeight="medium">
                    <Link style={{ color: '#0000FF' }} to={`/orders/${id}`}>
                        {id}
                    </Link>
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

// Declaring default props for IdCell
IdCell.defaultProps = {};

export default IdCell;
