/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { ReactNode } from 'react';

// react-router-dom components
import { Link, useMatches } from 'react-router-dom';

// @mui material components
import { Breadcrumbs as MuiBreadcrumbs } from '@mui/material';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// i18n
import { useTranslation } from 'react-i18next';

// Declaring props types for the Breadcrumbs
interface Props {
    icon: ReactNode;
    title: string;
    route: string | string[];
    light?: boolean;
    [key: string]: any;
}

function Breadcrumbs({ icon, title, route, light }: Props): JSX.Element {
    const matches: any = useMatches();
    const { t } = useTranslation('routes');

    let prefix = '';
    matches.forEach((match: any) => {
        prefix = match.handle?.breadcrumbsPrefix;
    });

    const routes: string[] | any = route.slice(0, -1);
    return (
        <MDBox mr={{ xs: 0, xl: 8 }}>
            <MuiBreadcrumbs
                sx={{
                    '& .MuiBreadcrumbs-separator': {
                        color: ({ palette: { white, grey } }) => (light ? white.main : grey[600])
                    }
                }}
            >
                <Link to="/">
                    <MDTypography
                        component="span"
                        variant="body2"
                        color={light ? 'white' : 'dark'}
                        opacity={light ? 0.8 : 0.5}
                        sx={{ lineHeight: 0 }}
                    >
                        <Icon>{icon}</Icon>
                    </MDTypography>
                </Link>
                {routes.map((el: string) => (
                    <Link to={`/${el}`} key={el}>
                        <MDTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color={light ? 'white' : 'dark'}
                            opacity={light ? 0.8 : 0.5}
                            sx={{ lineHeight: 0 }}
                        >
                            {t(el)}
                        </MDTypography>
                    </Link>
                ))}
                <MDTypography
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    color={light ? 'white' : 'dark'}
                    sx={{ lineHeight: 0 }}
                >
                    {t(title.replace('-', ' '))}
                </MDTypography>
            </MuiBreadcrumbs>
            <MDTypography
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                color={light ? 'white' : 'dark'}
                noWrap
            >
                {prefix}
                {t(title.replace('-', ' '))}
            </MDTypography>
        </MDBox>
    );
}

// Declaring default props for Breadcrumbs
Breadcrumbs.defaultProps = {
    light: false
};

export default Breadcrumbs;
