/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// @mui material components
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useTranslation } from 'react-i18next';

// Declaring props types for StatusCell
interface Props {
    icon: string;
    color:
        | 'primary'
        | 'secondary'
        | 'info'
        | 'success'
        | 'warning'
        | 'error'
        | 'dark'
        | 'light'
        | 'white'
        | 'default';
    status: string;
}

function StatusCell({ icon, color, status }: Props): JSX.Element {
    const { t } = useTranslation("orderState");

    function getStatus() {
        switch(status) {
            case "New":
                return t("new");
            case "Accepted":
                return t("inProgress");
            case "Shipped":
                return t("shipped");
            default:
                return "Unknown";
        }
    }

    return (
        <MDBox display="flex" alignItems="center">
            <MDBox mr={1}>
                <MDButton variant="contained" color={color} size="small" iconOnly circular>
                    <Icon sx={{ fontWeight: 'bold' }}>{icon}</Icon>
                </MDButton>
            </MDBox>
            <MDTypography variant="caption" fontWeight="medium" color="text" sx={{ lineHeight: 0 }}>
                {getStatus()}
            </MDTypography>
        </MDBox>
    );
}

export default StatusCell;
