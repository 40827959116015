/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useMemo, ReactNode } from 'react';

// react-chartjs-2 components
import 'chart.js/auto';
import { Bar } from 'react-chartjs-2';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// ReportsBarChart configurations
import configs from 'components/Charts/BarCharts/ReportsBarChart/configs';

// Declaring props types for ReportsBarChart
interface Props {
    color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'dark';
    title: string;
    description?: string | ReactNode;
    date: string;
    chart: {
        labels: string[];
        datasets: {
            label: string;
            data: number[];
        };
    };
    [key: string]: any;
}

function ReportsBarChart({ color, title, description, date, chart }: Props): JSX.Element {
    const { data, options } = configs(chart.labels || [], chart.datasets || {});

    return (
        <Card sx={{ height: '100%' }}>
            <MDBox padding="1rem">
                {useMemo(
                    () => (
                        <MDBox
                            variant="gradient"
                            bgColor={color}
                            borderRadius="lg"
                            coloredShadow={color}
                            py={2}
                            pr={0.5}
                            mt={-5}
                            height="12.5rem"
                        >
                            {/* @ts-ignore */}
                            <Bar data={data} options={options} />
                        </MDBox>
                    ),
                    [data, options, color]
                )}
                <MDBox pt={3} pb={1} px={1}>
                    <MDTypography variant="h6" textTransform="capitalize">
                        {title}
                    </MDTypography>
                    <MDTypography component="div" variant="button" color="text" fontWeight="light">
                        {description}
                    </MDTypography>
                    <Divider />
                    <MDBox display="flex" alignItems="center">
                        <MDTypography
                            variant="button"
                            color="text"
                            lineHeight={1}
                            sx={{ mt: 0.15, mr: 0.5 }}
                        >
                            <Icon>schedule</Icon>
                        </MDTypography>
                        <MDTypography variant="button" color="text" fontWeight="light">
                            {date}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ReportsBarChart
ReportsBarChart.defaultProps = {
    color: 'dark',
    description: ''
};

export default ReportsBarChart;
