/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React TS examples components
import TimelineItem from 'components/Timeline/TimelineItem';
import { GetOrderDetailsQuery } from 'gql/graphql';
import { useTranslation } from 'react-i18next';

import dayjs from 'dayjs';

/*
<TimelineItem
    color="secondary"
    icon="warehouse"
    title="Order picked up"
    dateTime="22 DEC 8:10 AM"
/>
<TimelineItem
    color="success"
    icon="local_shipping"
    title="Order delivered"
    dateTime="22 DEC 4:54 PM"
    lastItem
/>
*/

function OrdersOverview({ order }: { order: GetOrderDetailsQuery['orders'][0] }): JSX.Element {
    const { t } = useTranslation('orderDetails');

    const pastDue = dayjs().isAfter(dayjs(order.shippingDueDate));

    function getNewTrackingItems() {
        return (
            <MDBox mt={2}>
                <TimelineItem
                    color="success"
                    icon="grade"
                    title={t('orderTracking.orderReceived')}
                    dateTime={dayjs(order.orderTimestamp).format('YYYY/MM/DD H:mm:ss')}
                    lastItem
                />
                <TimelineItem
                    color={pastDue ? 'error' : 'warning'}
                    icon="local_shipping"
                    title={t('orderTracking.shippingDueDate')}
                    dateTime={dayjs(order.orderTimestamp)
                        .add(5, 'day')
                        .format('YYYY/MM/DD 23:59:59')}
                    lastItem
                />
            </MDBox>
        );
    }
    function getAcceptedTrackingItems() {
        if (dayjs(order.acceptanceTimestamp).isAfter(order.shippingDueDate)) {
            return (
                <MDBox mt={2}>
                    <TimelineItem
                        color="secondary"
                        icon="grade"
                        title={t('orderTracking.orderReceived')}
                        dateTime={dayjs(order.orderTimestamp).format('YYYY/MM/DD H:mm:ss')}
                    />
                    <TimelineItem
                        color="error"
                        icon="local_shipping"
                        title={t('orderTracking.shippingDueDate')}
                        dateTime={dayjs(order.orderTimestamp)
                            .add(5, 'day')
                            .format('YYYY/MM/DD 23:59:59')}
                    />
                    <TimelineItem
                        color="success"
                        icon="add_task"
                        title={t('orderTracking.orderAccepted')}
                        dateTime={dayjs(order.acceptanceTimestamp).format('YYYY/MM/DD H:mm:ss')}
                        lastItem
                    />
                </MDBox>
            );
        }
        return (
            <MDBox mt={2}>
                <TimelineItem
                    color="secondary"
                    icon="grade"
                    title={t('orderTracking.orderReceived')}
                    dateTime={dayjs(order.orderTimestamp).format('YYYY/MM/DD H:mm:ss')}
                />
                <TimelineItem
                    color="success"
                    icon="add_task"
                    title={t('orderTracking.orderAccepted')}
                    dateTime={dayjs(order.acceptanceTimestamp).format('YYYY/MM/DD H:mm:ss')}
                    lastItem
                />
                <TimelineItem
                    color={pastDue ? 'error' : 'warning'}
                    icon="local_shipping"
                    title={t('orderTracking.shippingDueDate')}
                    dateTime={dayjs(order.orderTimestamp)
                        .add(5, 'day')
                        .format('YYYY/MM/DD 23:59:59')}
                    lastItem
                />
            </MDBox>
        );
    }
    function getShippedTrackingItems() {
        if (pastDue) {
            return (
                <MDBox mt={2}>
                    <TimelineItem
                        color="secondary"
                        icon="grade"
                        title={t('orderTracking.orderReceived')}
                        dateTime={dayjs(order.orderTimestamp).format('YYYY/MM/DD H:mm:ss')}
                    />
                    <TimelineItem
                        color="secondary"
                        icon="add_task"
                        title={t('orderTracking.orderAccepted')}
                        dateTime={dayjs(order.acceptanceTimestamp).format('YYYY/MM/DD H:mm:ss')}
                    />
                    <TimelineItem
                        color="error"
                        icon="local_shipping"
                        title={t('orderTracking.shippingDueDate')}
                        dateTime={dayjs(order.orderTimestamp)
                            .add(5, 'day')
                            .format('YYYY/MM/DD 23:59:59')}
                    />
                    <TimelineItem
                        color="success"
                        icon="description"
                        title={t('orderTracking.shippingDocumentsGenerated')}
                        dateTime={dayjs(order.shippingDocsGeneratedTimestamp).format(
                            'YYYY/MM/DD H:mm:ss'
                        )}
                        lastItem
                    />
                </MDBox>
            );
        }
        return (
            <MDBox mt={2}>
                <TimelineItem
                    color="secondary"
                    icon="grade"
                    title={t('orderTracking.orderReceived')}
                    dateTime={dayjs(order.orderTimestamp).format('YYYY/MM/DD H:mm:ss')}
                />
                <TimelineItem
                    color="secondary"
                    icon="add_task"
                    title={t('orderTracking.orderAccepted')}
                    dateTime={dayjs(order.acceptanceTimestamp).format('YYYY/MM/DD H:mm:ss')}
                />
                <TimelineItem
                    color="success"
                    icon="description"
                    title={t('orderTracking.shippingDocumentsGenerated')}
                    dateTime={dayjs(order.shippingDocsGeneratedTimestamp).format(
                        'YYYY/MM/DD H:mm:ss'
                    )}
                    lastItem
                />
                <TimelineItem
                    color="warning"
                    icon="local_shipping"
                    title={t('orderTracking.shippingDueDate')}
                    dateTime={dayjs(order.orderTimestamp)
                        .add(5, 'day')
                        .format('YYYY/MM/DD 23:59:59')}
                    lastItem
                />
            </MDBox>
        );
    }

    return (
        <>
            <MDTypography variant="h6" fontWeight="medium">
                {t('orderTracking.orderTracking')}
            </MDTypography>
            {order.state === 'New' && getNewTrackingItems()}
            {order.state === 'Accepted' && getAcceptedTrackingItems()}
            {order.state === 'Shipped' && getShippedTrackingItems()}
        </>
    );
}

export default OrdersOverview;
