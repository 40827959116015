/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState, useEffect } from 'react';

// GraphQL
import { graphql } from 'gql';
import { useMutation, useQuery } from 'urql';

// @mui material components
import Divider from '@mui/material/Divider';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Icon from '@mui/material/Icon';
import { Theme } from '@mui/material/styles';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import MDDatePicker from 'components/MDDatePicker';

// Images
import Image from 'mui-image';

// Custom styles for the Configurator
import ShippingDrawerRoot from 'components/ShippingDrawer/ShippingDrawerRoot';

// Material Dashboard 2 PRO React context
import { useMaterialUIController, setOpenShippingDrawer } from 'context';
import { useParams } from 'react-router-dom';
import MDInput from 'components/MDInput';
import dayjs from 'dayjs';
import japanPostLogo from 'assets/images/jplogo.png';

const ShipOrder = graphql(`
    mutation ShipOrder($orderId: ID!, $packageWeightGrams: Int!, $shipDate: String!) {
        shipOrder(
            orderParams: {
                orderId: $orderId
                packageWeightGrams: $packageWeightGrams
                shipDate: $shipDate
            }
        ) {
            trackingNumber
        }
    }
`);

function ShippingDrawer({ orderId }: { orderId: string }): JSX.Element {
    const { orderNumber } = useParams();
    const [controller, dispatch] = useMaterialUIController();
    const [weight, setWeight] = useState(0);
    const [shipDate, setShipDate] = useState(dayjs());

    const [shipOrderResult, shipOrder] = useMutation(ShipOrder);

    const { openShippingDrawer, darkMode } = controller;

    const handleCloseShippingDrawer = () => setOpenShippingDrawer(dispatch, false);

    const shippingStart = dayjs();
    const shippingEnd = shippingStart.add(5, 'day');

    return (
        <ShippingDrawerRoot variant="permanent" ownerState={{ openShippingDrawer }}>
            <MDBox
                display="flex"
                justifyContent="space-between"
                alignItems="baseline"
                pt={4}
                pb={0.5}
                px={3}
            >
                <MDBox>
                    <MDTypography variant="h5">Ship Order #{orderNumber}</MDTypography>
                </MDBox>
                <Icon
                    sx={({ typography: { size }, palette: { dark, white } }) => ({
                        fontSize: `${size.lg} !important`,
                        color: darkMode ? white.main : dark.main,
                        stroke: 'currentColor',
                        strokeWidth: '2px',
                        cursor: 'pointer',
                        transform: 'translateY(5px)'
                    })}
                    onClick={handleCloseShippingDrawer}
                >
                    close
                </Icon>
            </MDBox>
            <Divider />

            <MDBox px={3} pt={1} pb={2}>
                <Image src="/jplogo.png" width="70%" duration={0} />
            </MDBox>
            <MDBox pt={0.5} pb={3} px={3}>
                <MDBox>
                    <MDTypography variant="h6">Ship Date</MDTypography>
                </MDBox>
                <MDBox pt={1}>
                    <MDDatePicker
                        input={{
                            placeholder: 'Select date...',
                            fullWidth: true
                        }}
                        options={{
                            defaultDate: shippingStart.format('YYYY-MM-DD'),
                            enable: [
                                {
                                    from: shippingStart.format('YYYY-MM-DD'),
                                    to: shippingEnd.format('YYYY-MM-DD')
                                }
                            ]
                        }}
                        onChange={(date: any) => {
                            setShipDate(dayjs(date));
                        }}
                    />
                </MDBox>
                <MDBox mt={3} lineHeight={1}>
                    <MDTypography variant="h6">Total Weight</MDTypography>
                    <MDTypography variant="button" color="text">
                        Final weight including all packaging
                    </MDTypography>
                </MDBox>
                <MDBox>
                    <MDInput
                        variant="outlined"
                        placeholder="Weight (g)"
                        fullWidth
                        onChange={(e: any) => {
                            setWeight(e.target.value);
                        }}
                    />
                </MDBox>
                <Divider />
                <MDBox mt={3} mb={2}>
                    <MDBox mb={2}>
                        <MDButton
                            target="_blank"
                            rel="noreferrer"
                            color="info"
                            variant="gradient"
                            fullWidth
                            onClick={() => {
                                const variables = {
                                    orderId,
                                    packageWeightGrams: +weight,
                                    shipDate: shipDate.toISOString()
                                };
                                shipOrder(variables).then((result) => {
                                    handleCloseShippingDrawer();
                                });
                            }}
                        >
                            Generate Shipping Documents
                        </MDButton>
                    </MDBox>
                </MDBox>
            </MDBox>
        </ShippingDrawerRoot>
    );
}

export default ShippingDrawer;
