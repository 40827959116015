import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Client, Provider, fetchExchange } from 'urql';
import { cacheExchange } from '@urql/exchange-graphcache';
import { authExchange } from '@urql/exchange-auth';

function UrqlProvider({ children }: { children: any }) {
    const { isAuthenticated, getAccessTokenSilently } = useAuth0();

    const client = new Client({
        url: process.env.REACT_APP_GRAPHQL_API_URL as string,
        exchanges: [
            cacheExchange({
                keys: {
                    LineItemsAggregate: () => null,
                    LineItemsAggregateFields: () => null,
                    LineItemsSumFields: () => null,
                    OrdersAggregate: () => null,
                    OrdersAggregateFields: () => null
                },
                updates: {
                    Mutation: {
                        shipOrder(_result, args: any, cache, _info) {
                            cache.invalidate({
                                __typename: 'Orders',
                                id: args.orderParams.orderId
                            });
                        },
                        updateOrdersByPk(_result, args: any, cache, _info) {
                            cache.invalidate({
                                __typename: 'Orders',
                                id: args.pkColumns.id
                            });
                        },
                        updateProductsByPk(_result, args: any, cache, _info) {
                            cache.invalidate({
                                __typename: 'Products',
                                id: args.pkColumns.id
                            });
                        },
                        updateOrdersMany(_result, args: any, cache, _info) {
                            // @ts-ignore
                            _result.updateOrdersMany[0].returning.forEach((res: any) => {
                                cache.invalidate({
                                    __typename: 'Orders',
                                    id: res.id
                                });
                            });
                        }
                    }
                }
            }),
            authExchange(async (utils) => {
                let token: string | void | null = null;
                if (isAuthenticated) {
                    token = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.REACT_APP_GRAPHQL_API_URL,
                            scope: 'openid profile email offline_access'
                        }
                    }).catch(async (e) => {});
                }

                return {
                    addAuthToOperation(operation) {
                        if (!token) return operation;
                        return utils.appendHeaders(operation, {
                            Authorization: `Bearer ${token}`
                        });
                    },
                    didAuthError(error, _operation) {
                        return error.graphQLErrors.some((e) => e.extensions?.code === 'FORBIDDEN');
                    },
                    async refreshAuth() {
                        getAccessTokenSilently();
                    }
                };
            }) as any,
            fetchExchange
        ],
        fetchOptions: () => ({
            headers: {
                // "x-hasura-preferred-lang": "ja-JP"
            }
        })
    });

    return <Provider value={client}>{children}</Provider>;
}

export default UrqlProvider;
