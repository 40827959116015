/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// GraphQL
import { graphql } from 'gql';
import { useQuery } from 'urql';

// @mui material components
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from 'components/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'components/Navbars/DashboardNavbar';
import Footer from 'components/Footer';

// OrderDetails page components
import Header from 'layouts/order-details/components/Header';
import OrderInfo from 'layouts/order-details/components/OrderInfo';
import TrackOrder from 'layouts/order-details/components/TrackOrder';
import CustomerInformation from 'layouts/order-details/components/CustomerInformation';
import OrderSummary from 'layouts/order-details/components/OrderSummary';
import { useParams } from 'react-router-dom';
import LoadingSpinner from 'components/LoadingSpinner';
import ShippingDrawer from 'components/ShippingDrawer';

const GetOrderDetails = graphql(`
    query GetOrderDetails($orderNumber: Int!) {
        orders(where: { orderNumber: { _eq: $orderNumber } }) {
            id
            state
            orderTimestamp
            acceptanceTimestamp
            shippingDate
            shippingDocsGeneratedTimestamp
            shippingDocumentsUrl
            shippingDueDate
            totalTransferValue
            lineItems {
                id
                lineNumber
                quantity
                transferPrice
                salePrice
                product {
                    id
                    productCode
                    productName
                }
            }
            destinationAddressName
            destinationAddressLine1
            destinationAddressLine2
            destinationAddressLine3
            destinationCity
            destinationProvince
            destinationPostalCode
            destinationCountry {
                id
                name
            }
            org {
                id
                currencyCode
            }
        }
    }
`);

function OrderDetails(): JSX.Element {
    const { orderNumber } = useParams();
    const [result, reexecuteQuery] = useQuery({
        query: GetOrderDetails,
        variables: { orderNumber: +orderNumber }
    });

    const { data, fetching, error } = result;

    if (fetching) {
        return <LoadingSpinner />;
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <MDBox my={6}>
                <Grid container spacing={3} justifyContent="center">
                    <Grid item xs={12} lg={8}>
                        <Card sx={{ px: 1, py: 1 }}>
                            <MDBox pt={2} px={2}>
                                <Header order={data.orders[0]} />
                            </MDBox>
                            <Divider />
                            <MDBox pt={1} pb={3} px={2}>
                                <MDBox mb={3}>
                                    <OrderInfo order={data.orders[0]} />
                                </MDBox>
                                <Divider />
                                <MDBox mt={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} md={6} lg={3}>
                                            <TrackOrder order={data.orders[0]} />
                                        </Grid>
                                        <Grid item xs={12} md={6} lg={4}>
                                            <CustomerInformation order={data.orders[0]} />
                                        </Grid>
                                        <Grid item xs={12} lg={5}>
                                            <OrderSummary order={data.orders[0]} />
                                        </Grid>
                                    </Grid>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>
            <Footer />
            <ShippingDrawer orderId={data.orders[0].id} />
        </DashboardLayout>
    );
}

export default OrderDetails;
