/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessDays from 'dayjs-business-days2';

// Material Dashboard 2 PRO React TS components
import MDTypography from 'components/MDTypography';

// MUI components
import Stack from '@mui/material/Stack';
import { Icon } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

// Declaring props types for DefaultCell
interface Props {
    timestamp: string;
    row: any;
}

type BusinessDaysOptions = {
    holidays: string[];
    holidayFormat: string;
};

function DateCell({ timestamp, row }: Props): JSX.Element {
    // const displayDate = dayjs(timestamp).tz('Japan').format('YYYY-MM-DD\xa0\xa0h:mm A');
    const displayDate = dayjs(timestamp).format('YYYY-MM-DD');
    return (
        <Stack>
            <MDTypography variant="caption" fontWeight="medium" color="text">
                {displayDate}
            </MDTypography>
        </Stack>
    );
}

// Declaring default props for DefaultCell
DateCell.defaultProps = {};

export default DateCell;
