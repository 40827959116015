/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// GraphQL
import { graphql } from 'gql';
import { useMutation, useQuery } from 'urql';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import { useTranslation, Trans } from 'react-i18next';
import MDBadge from 'components/MDBadge';
import { useParams } from 'react-router-dom';
import { GetOrderDetailsQuery } from 'gql/graphql';
import { Grid, Stack } from '@mui/material';
import { setOpenShippingDrawer, useMaterialUIController } from 'context';
import dayjs from 'dayjs';

const UpdateOrderState = graphql(`
    mutation UpdateOrderState($id: uuid!, $state: String!, $acceptanceTimestamp: timestamptz!) {
        updateOrdersByPk(
            pkColumns: { id: $id }
            _set: { state: $state, acceptanceTimestamp: $acceptanceTimestamp }
        ) {
            id
            state
            acceptanceTimestamp
        }
    }
`);

function Header({ order }: { order: GetOrderDetailsQuery['orders'][0] }): JSX.Element {
    const [updateOrderStateResult, updateOrderState] = useMutation(UpdateOrderState);
    const { orderNumber } = useParams();
    const { t } = useTranslation('orderDetails');
    const [controller, dispatch] = useMaterialUIController();
    const { openShippingDrawer } = controller;
    const handleShippingDrawerOpen = () => setOpenShippingDrawer(dispatch, !openShippingDrawer);
    const buttonWidth = 140;
    const pastDue = dayjs().isAfter(dayjs(order.shippingDueDate));

    function getBadge() {
        switch (order.state) {
            case 'New':
                if (pastDue) {
                    return (
                        <Stack direction="row" spacing={1} mt={1}>
                            <MDBadge
                                variant="gradient"
                                color="warning"
                                size="xs"
                                badgeContent={t('orderState:new')}
                                container
                            />
                            <MDBadge
                                variant="gradient"
                                color="error"
                                size="xs"
                                badgeContent={t('orderState:pastDue')}
                                container
                            />
                        </Stack>
                    );
                }
                return (
                    <MDBadge
                        variant="gradient"
                        color="warning"
                        size="xs"
                        badgeContent={t('orderState:new')}
                        container
                    />
                );

            case 'Accepted':
                if (pastDue) {
                    return (
                        <Stack direction="row" spacing={1} mt={1}>
                            <MDBadge
                                variant="gradient"
                                color="info"
                                size="xs"
                                badgeContent={t('orderState:inProgress')}
                                container
                            />
                            <MDBadge
                                variant="gradient"
                                color="error"
                                size="xs"
                                badgeContent={t('orderState:pastDue')}
                                container
                            />
                        </Stack>
                    );
                }
                return (
                    <MDBadge
                        variant="gradient"
                        color="info"
                        size="xs"
                        badgeContent={t('orderState:inProgress')}
                        container
                    />
                );

            case 'Shipped':
                if (pastDue) {
                    return (
                        <Stack direction="row" spacing={1} mt={1}>
                            <MDBadge
                                variant="gradient"
                                color="success"
                                size="xs"
                                badgeContent={t('orderState:shipped')}
                                container
                            />
                            <MDBadge
                                variant="gradient"
                                color="error"
                                size="xs"
                                badgeContent={t('orderState:pastDue')}
                                container
                            />
                        </Stack>
                    );
                }
                return (
                    <MDBadge
                        variant="gradient"
                        color="success"
                        size="xs"
                        badgeContent={t('orderState:shipped')}
                        container
                    />
                );
            default:
                return (
                    <MDBadge
                        variant="gradient"
                        color="dark"
                        size="xs"
                        badgeContent={order.state}
                        container
                    />
                );
        }
    }
    function getButtons() {
        switch (order.state) {
            case 'New':
                return (
                    <Grid item>
                        <MDButton
                            variant="gradient"
                            color="primary"
                            sx={{ width: buttonWidth, paddingLeft: 0, paddingRight: 0 }}
                            onClick={() => {
                                const variables = {
                                    id: order.id,
                                    state: 'Accepted',
                                    acceptanceTimestamp: dayjs().toISOString()
                                };
                                updateOrderState(variables);
                            }}
                        >
                            {t('header.acceptOrder')}
                        </MDButton>
                    </Grid>
                );
            case 'Accepted':
                return (
                    <Grid item>
                        <MDButton
                            variant="gradient"
                            color="primary"
                            sx={{ width: buttonWidth, paddingLeft: 0, paddingRight: 0 }}
                            onClick={() => {
                                handleShippingDrawerOpen();
                            }}
                        >
                            {t('header.shipOrder')}
                        </MDButton>
                    </Grid>
                );
            case 'Shipped':
                return (
                    <Grid item>
                        <MDButton
                            variant="gradient"
                            color="primary"
                            sx={{ width: buttonWidth, paddingLeft: 0, paddingRight: 0 }}
                            href={order.shippingDocumentsUrl}
                            target="_blank"
                        >
                            {t('header.printShippingDocs')}
                        </MDButton>
                    </Grid>
                );
            default:
                return <div />;
        }
    }

    return (
        <MDBox display="flex" justifyContent="space-between" alignItems="center">
            <MDBox>
                <MDBox mb={1} minWidth={130}>
                    <MDTypography variant="h5" fontWeight="medium">
                        {t('header.orderDetails')}
                    </MDTypography>
                </MDBox>
                <MDTypography component="p" variant="button" color="text">
                    Order no. <b>{orderNumber}</b>
                </MDTypography>
                {getBadge()}
            </MDBox>
            <Grid
                container
                columns={{ xs: 1, md: 2 }}
                rowSpacing={1}
                columnSpacing={1}
                justifyContent="flex-end"
                alignItems="center"
            >
                {getButtons()}
                <Grid item>
                    <MDButton
                        variant="gradient"
                        color="info"
                        sx={{ width: buttonWidth, paddingLeft: 0, paddingRight: 0 }}
                    >
                        {t('header.invoice')}
                    </MDButton>
                </Grid>
            </Grid>
        </MDBox>
    );
}

export default Header;
