/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import PropTypes from 'prop-types';

// @mui material components
import Grid from '@mui/material/Grid';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// i18n
import { useTranslation } from 'react-i18next';

// Data
import { GetOrderDetailsQuery } from 'gql/graphql';
import DataTable from 'components/Tables/DataTable';
import dataTableData from '../../data/dataTableData';

function OrderInfo({ order }: { order: GetOrderDetailsQuery['orders'][0] }): JSX.Element {
    const { t } = useTranslation('orderDetails');

    function renderTranslatedTable() {
        dataTableData.columns.forEach((column) => {
            column.Header = t(column.Header);
        });
        return dataTableData;
    }
    dataTableData.rows = order.lineItems;

    return (
        <Grid container spacing={3} alignItems="center">
            <Grid item xs={12}>
                <MDBox display="flex" alignItems="center">
                    <MDBox lineHeight={1}>
                        <MDTypography variant="h6" fontWeight="medium">
                            {t('orderInfo.orderContents')}
                        </MDTypography>
                    </MDBox>
                </MDBox>
                <DataTable
                    table={renderTranslatedTable()}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    isSorted={false}
                />
            </Grid>
        </Grid>
    );
}

export default OrderInfo;
