/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import AU from "assets/images/icons/flags/AU.png";
import World from "assets/images/icons/flags/world.png";

const salesTableData = [
  {
    "dashboard:salesByCountry.country": [World, 'dashboard:salesByCountry.worldwide'],
    "dashboard:salesByCountry.sales": 488,
    "dashboard:salesByCountry.revenue": "¥7,723,088",
    "dashboard:salesByCountry.share": "100%"
  },
  {
    "dashboard:salesByCountry.country": [US, 'dashboard:salesByCountry.unitedStates'],
    "dashboard:salesByCountry.sales": 219,
    "dashboard:salesByCountry.revenue": "¥3,475,389",
    "dashboard:salesByCountry.share": "45%"
  },
  {
    "dashboard:salesByCountry.country": [DE, 'dashboard:salesByCountry.germany'],
    "dashboard:salesByCountry.sales": 117,
    "dashboard:salesByCountry.revenue": "¥1,853,541",
    "dashboard:salesByCountry.share": "24%"
  },
  {
    "dashboard:salesByCountry.country": [GB, 'dashboard:salesByCountry.greatBritain'],
    "dashboard:salesByCountry.sales": 83,
    "dashboard:salesByCountry.revenue": "¥1,312,924",
    "dashboard:salesByCountry.share": "17%"
  },
  { 
    "dashboard:salesByCountry.country": [AU, 'dashboard:salesByCountry.australia'], 
    "dashboard:salesByCountry.sales": 68, 
    "dashboard:salesByCountry.revenue": "¥1,081,232",
    "dashboard:salesByCountry.share": "14%"
  },
];

export default salesTableData;
