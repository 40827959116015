import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export function Auth0ProviderWithNavigate({ children }: { children: any }) {
    const navigate = useNavigate();

    const domain = process.env.REACT_APP_AUTH0_DOMAIN;
    const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_AUTH0_CALLBACK_URL;

    const onRedirectCallback = (appState: any) => {
        navigate(appState?.returnTo || window.location.pathname);
    };

    if (!(domain && clientId && redirectUri)) {
        return null;
    }

    return (
        <Auth0Provider
            domain={domain}
            clientId={clientId}
            authorizationParams={{
                redirect_uri: redirectUri,
                ui_locales: 'en',
                audience: process.env.REACT_APP_GRAPHQL_API_URL,
                scope: 'openid profile email offline_access'
            }}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens
            useRefreshTokensFallback
        >
            {children}
        </Auth0Provider>
    );
}

export default Auth0ProviderWithNavigate;
