/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from 'App';

// Material Dashboard 2 PRO React TS Context Provider
import { MaterialUIControllerProvider } from 'context';
import Auth0ProviderWithNavigate from 'Auth0ProviderWithNavigate';
import UrqlProvider from 'UrqlProvider';
import Dashboard from 'layouts/dashboard';
import OrderList from 'layouts/order-list';
import OrderDetails from 'layouts/order-details';
import Login from 'layouts/login';
import './i18n';
import { useAuth0 } from '@auth0/auth0-react';
import LogoutPage from 'Logout';
import Account from 'layouts/account';

const root = createRoot(document.getElementById('root'));

function AppWrapper() {
    return (
        <MaterialUIControllerProvider>
            <Auth0ProviderWithNavigate>
                <UrqlProvider>
                    <App />
                </UrqlProvider>
            </Auth0ProviderWithNavigate>
        </MaterialUIControllerProvider>
    );
}

const router = createBrowserRouter([
    {
        path: '/',
        element: <AppWrapper />,
        children: [
            {
                index: true,
                element: <Dashboard />
            }
        ]
    },
    {
        path: '*',
        element: <AppWrapper />,
        children: [
            {
                path: 'dashboard',
                element: <Dashboard />
            },
            {
                path: 'orders',
                element: <OrderList />
            },
            {
                path: 'orders/:orderNumber',
                element: <OrderDetails />,
                handle: {
                    breadcrumbsPrefix: 'Order #'
                }
            },
            {
                path: 'account',
                element: <Account />
            },
            {
                path: 'inventory',
                element: <OrderList />
            },
            {
                path: 'logout',
                element: <LogoutPage />
            }
        ]
    }
]);

root.render(<RouterProvider router={router} />);
