import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from 'components/LoadingSpinner';
import React from 'react';

function LogoutPage() {
    const { logout } = useAuth0();
    logout({
        logoutParams: {
            returnTo: window.location.origin
        }
    });
    return <LoadingSpinner />;
}

export default LogoutPage;
