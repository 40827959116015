/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { ReactNode } from 'react';

// @mui material components
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import Icon from '@mui/material/Icon';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

import { Trans, useTranslation } from 'react-i18next';

// Declaring props types for CompleStatisticsCard
interface Props {
    color?: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'error' | 'light' | 'dark';
    title: string;
    count: string | number;
    dataCaption?: {
        color:
            | 'primary'
            | 'secondary'
            | 'info'
            | 'success'
            | 'warning'
            | 'error'
            | 'dark'
            | 'white';
        amount: string | number;
        label?: string;
        reverse?: boolean;
        i18nKey?: string;
    };
    icon: ReactNode;
    
    [key: string]: any;
}

function ComplexStatisticsCard({
    color,
    title,
    count,
    dataCaption,
    icon
}: Props): JSX.Element {
    const { t } = useTranslation();
    function getNormalFormatting() {
        return (
            <MDTypography component="p" variant="button" color="text" display="flex">
                <MDTypography
                    component="span"
                    variant="button"
                    fontWeight="bold"
                    color={dataCaption.color}
                >
                    {dataCaption.amount}
                </MDTypography>
                &nbsp;{dataCaption.label}
            </MDTypography>
        );
    }

    function getReverseFormatting() {
        return (
            <MDTypography component="p" variant="button" color="text" display="flex">
                {dataCaption.label}&nbsp;
                <MDTypography
                    component="span"
                    variant="button"
                    fontWeight="bold"
                    color={dataCaption.color}
                >
                    {dataCaption.amount}
                </MDTypography>
            </MDTypography>
        );
    }

    function getFormatting() {
        if(!dataCaption.i18nKey) {
            if(dataCaption.reverse) return getReverseFormatting();
            return getNormalFormatting();
        }
        return(
            <MDTypography component="p" variant="button" color="text" display="flex" style={{ whiteSpace: "pre-wrap"}}>
                <Trans i18nKey={dataCaption.i18nKey} values={{dataCaptionAmount: dataCaption.amount}}>
                    <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="bold"
                        color={dataCaption.color}
                    />
                </Trans>
            </MDTypography>
        );
    }

    return (
        <Card>
            <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === 'light' ? 'dark' : 'white'}
                    coloredShadow={color}
                    borderRadius="xl"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="4rem"
                    height="4rem"
                    mt={-3}
                >
                    <Icon fontSize="medium" color="inherit">
                        {icon}
                    </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {title}
                    </MDTypography>
                    <MDTypography variant="h4">{count}</MDTypography>
                </MDBox>
            </MDBox>
            <Divider />
            <MDBox pb={2} px={2}>
                {getFormatting()}
            </MDBox>
        </Card>
    );
}

// Declaring defualt props for ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
    color: 'info',
    dataCaption: {
        color: 'success',
        text: '',
        label: '',
        reverse: false,
        i8nKey: null
    }
};

export default ComplexStatisticsCard;
