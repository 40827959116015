/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from 'context';
import { GetOrderDetailsQuery } from 'gql/graphql';
import { useTranslation } from 'react-i18next';

function CustomerInformation({ order }: { order: GetOrderDetailsQuery['orders'][0] }): JSX.Element {
    const [controller] = useMaterialUIController();
    const { t } = useTranslation('orderDetails');
    const { darkMode } = controller;

    return (
        <>
            <MDTypography variant="h6" fontWeight="medium">
                {t('customerInformation.customerInformation')}
            </MDTypography>
            <MDBox
                component="li"
                display="flex"
                justifyContent="space-between"
                alignItems="flex-start"
                bgColor={darkMode ? 'transparent' : 'grey-100'}
                borderRadius="lg"
                p={3}
                mt={2}
            >
                <MDBox width="100%" display="flex" flexDirection="column" lineHeight={1}>
                    <MDBox mb={1} lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="medium">
                            {order.destinationAddressName}
                        </MDTypography>
                    </MDBox>
                    <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular">
                            {order.destinationAddressLine1}
                        </MDTypography>
                    </MDBox>
                    {order.destinationAddressLine2 && (
                        <MDBox lineHeight={0}>
                            <MDTypography variant="caption" fontWeight="regular">
                                {order.destinationAddressLine2}
                            </MDTypography>
                        </MDBox>
                    )}
                    {order.destinationAddressLine3 && (
                        <MDBox lineHeight={0}>
                            <MDTypography variant="caption" fontWeight="regular">
                                {order.destinationAddressLine2}
                            </MDTypography>
                        </MDBox>
                    )}
                    <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular">
                            {order.destinationCity}, {order.destinationProvince}
                        </MDTypography>
                    </MDBox>
                    <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular">
                            {order.destinationCountry.name}
                        </MDTypography>
                    </MDBox>
                    <MDBox lineHeight={0}>
                        <MDTypography variant="caption" fontWeight="regular">
                            {order.destinationPostalCode}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
        </>
    );
}

export default CustomerInformation;
