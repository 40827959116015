/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDAvatar from 'components/MDAvatar';
import { useTranslation, Trans } from 'react-i18next';

// Declaring props types for ProductCell
interface Props {
    image: string;
    name: string;
    orders: string | number;
}

function ProductCell({ image, name, orders }: Props): JSX.Element {
    const { t } = useTranslation("dashboard", {keyPrefix: ""});
    return (
        <MDBox display="flex" alignItems="center" pr={2}>
            <MDBox mr={2}>
                <MDAvatar src={image} alt={name} />
            </MDBox>
            <MDBox display="flex" flexDirection="column">
                <MDTypography variant="button" fontWeight="medium">
                    {name}
                </MDTypography>
                <MDTypography variant="button" fontWeight="regular" color="secondary">
                    <Trans i18nKey="dashboard:topSellingProducts.openOrdersMultiple" values={{numOrders: orders}}>
                    <MDTypography
                        component="span"
                        variant="button"
                        fontWeight="regular"
                        color="success"
                    />
                    </Trans>
                </MDTypography>
            </MDBox>
        </MDBox>
    );
}

export default ProductCell;
