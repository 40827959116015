/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import { GetOrderDetailsQuery } from 'gql/graphql';
import { useTranslation } from 'react-i18next';

function OrderSummary({ order }: { order: GetOrderDetailsQuery['orders'][0] }): JSX.Element {
    const { t } = useTranslation("orderDetails", { keyPrefix: "orderSummary" })
    const revenue = order.totalTransferValue;
    const transferPrice = order.lineItems.reduce(
        (partialSum, item) => partialSum + item.salePrice,
        0
    );
    const foreignExchangeFee = Math.round(transferPrice * 0.02 * 100) / 100;
    const marketplaceFee = Math.round(transferPrice * 0.25 * 100) / 100;
    const shippingFee = 12.23;
    const totalBeforeTax =
        Math.round((transferPrice + foreignExchangeFee + marketplaceFee + shippingFee) * 100) / 100;
    const taxes = Math.round(totalBeforeTax * 0.07 * 100) / 100;
    const total = totalBeforeTax + taxes;

    return (
        <>
            <MDBox mb={2}>
                <MDTypography variant="h6" fontWeight="medium">
                    {t("orderSummary")}
                </MDTypography>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="info">
                    {t("revenue")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium" color="info">
                        ¥{revenue}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    {t("transferPrice")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ${transferPrice.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    {t("foreignExchangeFee")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ${foreignExchangeFee.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    {t("marketplaceFee")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ${marketplaceFee.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    {t("shipping")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ${shippingFee.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mb={0.5}>
                <MDTypography variant="button" fontWeight="regular" color="text">
                    {t("taxes")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body2" fontWeight="medium">
                        ${taxes.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
            <MDBox display="flex" justifyContent="space-between" mt={3}>
                <MDTypography variant="body1" fontWeight="light" color="text">
                    {t("total")}
                </MDTypography>
                <MDBox ml={1}>
                    <MDTypography variant="body1" fontWeight="medium">
                        ${total.toFixed(2)}
                    </MDTypography>
                </MDBox>
            </MDBox>
        </>
    );
}

export default OrderSummary;
