import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    "en-US": {
        routes: {
            dashboard: "Dashboard",
            orders: "Orders",
            account: "Account",
            inventory: "Inventory",
        },
        sideNav: {
            profile: "Profile",
            settings: "Settings",
            logout: "Logout",
            dashboard: "Dashboard",
            orders: "Orders",
            account: "Account",
            inventory: "Inventory",
        },
        dataTable: {
            search: "Search...",
            entriesDisplay: "Showing {{entriesStart}} to {{entriesEnd}} of {{totalEntries}} entries"
        },
        orderState: {
            new: "New",
            inProgress: "In Progress",
            shipped: "Shipped",
            pastDue: "Past Due"
        },
        dashboard: {
            statisticsCardsfoo: {
                newOrders: "New Orders",
                newOrdersVsLastWeek: "vs. last week",
                inProgress: "In Progress",
                pastDue: "Past Due",
                accountBalance: "Account Balance",
            },
            statisticsCards: {
                newOrders: "New Orders",
                newOrdersVsLastWeekIncrease: "<0>+{{dataCaptionAmount}}%</0> vs. last week",
                newOrdersVsLastWeekDecrease: "<0>-{{dataCaptionAmount}}%</0> vs. last week",
                inProgress: "In Progress",
                totalOrderValueIncrease: "<0>+¥{{dataCaptionAmount}}</0> total order value",
                totalOrderValueDecrease: "<0>+¥{{dataCaptionAmount}}</0> total order value",
                pastDue: "Past Due",
                averageShipTime: "<0>{{dataCaptionAmount}} days</0> average ship time",
                accountBalance: "Account Balance",
                balanceChangeThisWeekIncrease: "<0>+¥{{dataCaptionAmount}}</0> this week",
                balanceChangeThisWeekDecrease: "<0>-¥{{dataCaptionAmount}}</0> this week"
            },
            salesByCountry: {
                salesByCountryYTD: "Sales by Country (Year to Date)",
                worldwide: "Worldwide",
                unitedStates: "United States",
                germany: "Germany",
                greatBritain: "Great Britain",
                australia: "Australia",
                country: "Country",
                sales: "Sales",
                revenue: "Revenue",
                share: "Share",
            },
            charts: {
                ordersThisWeek: "Orders This Week",
                ordersChangeWeekOverWeek: "<0>{{changeAmount}}</0> week-over-week",
                monthlySales: "Monthly Sales",
                salesChangeMonthOverMonth: "<0>{{changeAmount}}</0> month-over-month",
                monthlyRevenue: "Monthly Revenue (×10,000 JPY)",
                revenueChangeMonthOverMonth: "<0>{{changeAmount}}</0> month-over-month",
                justUpdated: "Just updated",
            },
            topSellingProducts: {
                topSellingProducts: "Top Selling Products",
                product: "Product",
                revenue: "Revenue",
                unitSales: "Unit Sales",
                trendMoM: "Trend (MoM)",
                openOrdersOne: "1 open order",
                openOrdersMultiple: "<0>{{numOrders}}</0> open orders"
            }
        },
        orderList: {
            acceptAll: "Accept All",
            filters: "Filters",
            exportCSV: "Export CSV",
            filtersDropdown: {
                statusNew: "Status: New",
                statusAccepted: "Status: Accepted",
                statusShipped: "Status: Shipped",
                statusCancelled: "Status: Cancelled",
                statusReturned: "Status: Returned",
                removeFilter: "Remove Filter"
            },
            tableHeader: {
                id: "ID",
                date: "date",
                status: "status",
                items: "items",
                revenue: "revenue"
            },
            alerts: {
                newOrdersOne: "You have 1 new order.",
                newOrdersMultiple: "You have {{newOrdersCount}} new orders."
            }
        },
        orderDetails: {
            header: {
                orderDetails: "Order Details",
                invoice: "View Invoice",
                acceptOrder: "Accept Order",
                shipOrder: "Ship Order",
                printShippingDocs: "Print Documents"
            },
            orderInfo: {
                orderContents: "Order Contents",
                tableHeader: {
                    productCode: "Product Code",
                    productName: "Product Name",
                    quantity: "Quantity",
                    revenue: "Revenue"
                }
            },
            orderTracking: {
                orderTracking: "Order Tracking",
                orderReceived: "Order received",
                orderAccepted: "Order accepted",
                shippingDocumentsGenerated: "Shipping documents generated",
                shippingDueDate: "Shipping due date"
            },
            customerInformation: {
                customerInformation: "Customer Information"
            },
            orderSummary: {
                orderSummary: "Order Summary",
                revenue: "Revenue (JPY):",
                transferPrice: "Transfer Price (USD):",
                foreignExchangeFee: "Foreign Exchange Fee (USD):",
                marketplaceFee: "Marketplace Fee (USD):",
                shipping: "Shipping (USD):",
                taxes: "Taxes (USD):",
                total: "Total (USD):"
            }
        },
        account: {
            statisticsCards: {
                balance: "Account Balance",
                availableForTransfer: "<0>¥{{dataCaptionAmount}}</0> available for transfer"
            },
            tableHeader: {
                id: "ID",
                date: "Date",
                description: "Description",
                amount: "Amount",
                fees: "Fees",
                net: "Net",
                totalFunds: "Total Funds",
                details: "Details"
            }
        },
        footer: {
            termsAndConditions: "Terms & Conditions",
            privacyPolicy: "Privacy Policy"
        },
        notifications: {
            noNewNotifications: "You have no new notifications.",
            newOrdersOne: "You have 1 new order.",
            newOrdersMultiple: "You have {{newOrdersCount}} new orders.",
            pastDueOrdersOne: "You have 1 order past due. Past due orders must be shipped immediately to avoid penalties.",
            pastDueOrdersMultiple: "You have {{pastDueOrdersCount}} orders past due. Past due orders must be shipped immediately to avoid penalties."
        }
    },
    "ja-JP": {
        routes: {
            dashboard: "ダッシュボード",
            orders: "注文",
            account: "口座",
            inventory: "在庫"
        },
        dataTable: {
            search: "検索...",
            entriesDisplay: "Showing {{entriesStart}} to {{entriesEnd}} of {{totalEntries}} entries"
        },
        orderState: {
            new: "新規",
            inProgress: "対応中",
            shipped: "発送済み",
            pastDue: "Past Due"

        },
        sideNav: {
            profile: "プロフィール",
            settings: "設定",
            logout: "ログアウト",
            dashboard: "ダッシュボード",
            orders: "注文",
            account: "口座",
            inventory: "在庫"
        },
        dashboard: {
            statisticsCards: {
                newOrders: "新規注文",
                newOrdersVsLastWeekIncrease: "先週比 <0>+{{dataCaptionAmount}}%</0>",
                newOrdersVsLastWeekDecrease: "先週比 <0>-{{dataCaptionAmount}}%</0>",
                inProgress: "対応中",
                totalOrderValueIncrease: "合計注文額 <0>+{{dataCaptionAmount}}円</0>",
                totalOrderValueDecrease: "合計注文額 <0>-{{dataCaptionAmount}}円</0>",
                pastDue: "期日超過",
                averageShipTime: "平均発送日数 <0>{{dataCaptionAmount}}日</0>",
                accountBalance: "口座残高",
                balanceChangeThisWeekIncrease: "今週 <0>+{{dataCaptionAmount}}円</0>",
                balanceChangeThisWeekDecrease: "今週 <0>-{{dataCaptionAmount}}円</0>"
            },
            salesByCountry: {
                salesByCountryYTD: "年初来の国別販売",
                worldwide: "世界",
                unitedStates: "米国",
                germany: "ドイツ",
                greatBritain: "英国",
                australia: "オーストラリア",
                country: "国",
                sales: "販売数",
                revenue: "売上",
                share: "シェア",
            },
            charts: {
                ordersThisWeek: "今週の発注",
                ordersChangeWeekOverWeek: "週次 <0>{{changeAmount}}</0>",
                monthlySales: "今月の販売数",
                salesChangeMonthOverMonth: "月次 <0>{{changeAmount}}</0>",
                monthlyRevenue: "今月の売上（万円）",
                revenueChangeMonthOverMonth: "月次 <0>{{changeAmount}}</0>",
                justUpdated: "先ほど更新済み",
            },
            topSellingProducts: {
                topSellingProducts: '販売トップ商品',
                product: "商品",
                revenue: "売上",
                unitSales: "商品販売数",
                trendMoM: "比較(月次)",
                openOrdersOne: "1 受注（未対応）",
                openOrdersMultiple: "<0>{{numOrders}}</0> 受注（未対応）"
            }
        },
        orderList: {
            acceptAll: "すべてを確認済みにする",
            filters: "フィルター",
            exportCSV: "CSVでダウンロード",
            filtersDropdown: {
                statusNew: "ステータス：新規",
                statusAccepted: "ステータス：確認済み",
                statusShipped: "ステータス：発送済み",
                statusCancelled: "ステータス：キャンセル",
                statusReturned: "ステータス：返品済み",
                removeFilter: "フィルターを外す"
            },
            tableHeader: {
                id: "番号",
                date: "日付",
                status: "ステータス",
                items: "商品",
                revenue: "売上"
            },
            alerts: {
                newOrdersOne: "新規の受注が 1 件あります。",
                newOrdersMultiple: "新規の受注が {{newOrdersCount}} 件あります。"
            }
        },
        orderDetails: {
            header: {
                orderDetails: "注文詳細",
                invoice: "送り状を見る",
                acceptOrder: "注文を受注する",
                shipOrder: "注文を配送する",
                printShippingDocs: "発送資料を印刷する"
            },
            orderInfo: {
                orderContents: "注文内容",
                tableHeader: {
                    productCode: "商品コード",
                    productName: "商品名",
                    quantity: "数量",
                    revenue: "売上"
                }
            },
            orderTracking: {
                orderTracking: "注文状況",
                orderReceived: "発注",
                orderAccepted: "受注",
                shippingDocumentsGenerated: "発送資料作成",
                shippingDueDate: "発送期限"
            },
            customerInformation: {
                customerInformation: "顧客情報"
            },
            orderSummary: {
                orderSummary: "注文内容",
                revenue: "売上 (円):",
                transferPrice: "配送料 (USドル):",
                foreignExchangeFee: "為替手数料 (USドル):",
                marketplaceFee: "マーケット利用料 (USドル):",
                shipping: "配送料 (USドル):",
                taxes: "税金 (USドル):",
                total: "合計 (USドル):"
            }
        },
        account: {
            statisticsCards: {
                balance: "Account Balance",
                availableForTransfer: "<0>¥{{dataCaptionAmount}}</0> available for transfer"
            },
            tableHeader: {
                id: "ID",
                date: "Date",
                description: "Description",
                amount: "Amount",
                fees: "Fees",
                net: "Net",
                totalFunds: "Total Funds",
                details: "Details"
            }
        },
        footer: {
            termsAndConditions: "利用規約",
            privacyPolicy: "プライバシーポリシー"
        },
        notifications: {
            noNewNotifications: "新しい通知はありません。",
            newOrdersOne: "新しい通知が 1 件あります。",
            newOrdersMultiple: "新しい通知が {{newOrdersCount}} 件あります.",
            pastDueOrdersOne: "期日を超過している注文が 1 件あります。期日を過ぎた注文はペナルティを避けるためにもすぐに発送してください。",
            pastDueOrdersMultiple: "期日を超過している注文が {{pastDueOrdersCount}} 件ありますます。期日を過ぎた注文はペナルティを避けるためにもすぐに発送してください。"
        }
    }
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: "en-US",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;