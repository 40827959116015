/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in
* all copies or substantial portions of the Software.
*/

import React, { useState, useEffect, useMemo } from 'react';

// react-router components
import { Navigate, Outlet, useLocation, useSearchParams } from 'react-router-dom';

// @mui material components
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Icon from '@mui/material/Icon';
import LanguageIcon from '@mui/icons-material/Language';

// Material Dashboard 2 PRO React TS components
import MDBox from 'components/MDBox';

// Material Dashboard 2 PRO React TS exampless
import Sidenav from 'components/Sidenav';
import Configurator from 'components/Configurator';

// Material Dashboard 2 PRO React TS themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// Material Dashboard 2 PRO React TS Dark Mode themes
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';

// Material Dashboard 2 PRO React TS routes
import sidenavDefinition from 'SidenavDefinition';

// Material Dashboard 2 PRO React TS contexts
import { useMaterialUIController, setMiniSidenav, setOpenConfigurator } from 'context';

// Images
import brandWhite from 'assets/images/logo-ct.png';
import brandDark from 'assets/images/logo-ct-dark.png';

// Auth0
import { useAuth0 } from '@auth0/auth0-react';
import LoadingSpinner from 'components/LoadingSpinner';

// i18n
import { useTranslation } from 'react-i18next';

export default function App() {
    const { isLoading, isAuthenticated, loginWithRedirect, getIdTokenClaims, error, logout } =
        useAuth0();
    const [controller, dispatch] = useMaterialUIController();
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode
    } = controller;
    const [onMouseEnter, setOnMouseEnter] = useState(false);
    const [rtlCache, setRtlCache] = useState(null);
    const { pathname } = useLocation();

    // Cache for the rtl
    useMemo(() => {
        const pluginRtl: any = rtlPlugin;
        const cacheRtl = createCache({
            key: 'rtl',
            stylisPlugins: [pluginRtl]
        });

        setRtlCache(cacheRtl);
    }, []);

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false);
            setOnMouseEnter(true);
        }
    };

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true);
            setOnMouseEnter(false);
        }
    };

    // Change the openConfigurator state
    const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction);
    }, [direction]);

    // Setting page scroll to 0 when changing the route
    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    const { i18n } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const GetToken = async () => {
            const claims = await getIdTokenClaims();
            if (claims) {
                const newLang = claims.preferred_lang;
                i18n.changeLanguage(newLang);
            }
        };
        if (searchParams.get('lang')) {
            i18n.changeLanguage(searchParams.get('lang') as string);
            return;
        }
        GetToken();
    }, [i18n, getIdTokenClaims, searchParams, isAuthenticated]);

    const configsButton = (
        <MDBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            width="3.25rem"
            height="3.25rem"
            bgColor="white"
            shadow="sm"
            borderRadius="50%"
            position="fixed"
            right="2rem"
            bottom="2rem"
            zIndex={99}
            color="dark"
            sx={{ cursor: 'pointer' }}
            onClick={handleConfiguratorOpen}
        >
            <Icon fontSize="small" color="inherit">
                settings
            </Icon>
        </MDBox>
    );

    if (isLoading) {
        return (
            <ThemeProvider theme={darkMode ? themeDark : theme}>
                <CssBaseline />
                <LoadingSpinner />
            </ThemeProvider>
        );
    }

    if (!isAuthenticated) {
        loginWithRedirect();
        return (
            <ThemeProvider theme={darkMode ? themeDark : theme}>
                <CssBaseline />
                <LoadingSpinner />
            </ThemeProvider>
        );
    }

    return direction === 'rtl' ? (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
                <CssBaseline />
                {layout === 'dashboard' && (
                    <>
                        <Sidenav
                            color={sidenavColor}
                            brand={
                                (transparentSidenav && !darkMode) || whiteSidenav
                                    ? brandDark
                                    : brandWhite
                            }
                            brandName="Artisano Portal"
                            routes={sidenavDefinition}
                            onMouseEnter={handleOnMouseEnter}
                            onMouseLeave={handleOnMouseLeave}
                        />
                        <Configurator />
                        {configsButton}
                    </>
                )}
                {layout === 'vr' && <Configurator />}
                <Outlet />
            </ThemeProvider>
        </CacheProvider>
    ) : (
        <ThemeProvider theme={darkMode ? themeDark : theme}>
            <CssBaseline />
            {layout === 'dashboard' && (
                <>
                    <Sidenav
                        color={sidenavColor}
                        brand={
                            (transparentSidenav && !darkMode) || whiteSidenav ? (
                                <LanguageIcon fontSize="medium" />
                            ) : (
                                <LanguageIcon fontSize="medium" htmlColor="#FFFFFF" />
                            )
                        }
                        brandName="Artisano Portal"
                        routes={sidenavDefinition}
                        onMouseEnter={handleOnMouseEnter}
                        onMouseLeave={handleOnMouseLeave}
                    />
                    <Configurator />
                    {configsButton}
                </>
            )}
            {layout === 'vr' && <Configurator />}
            <Outlet />
        </ThemeProvider>
    );
}
