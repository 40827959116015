/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.2
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React from 'react';

// Dayjs
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import dayjsBusinessDays from 'dayjs-business-days2';

// Material Dashboard 2 PRO React TS components
import MDTypography from 'components/MDTypography';

// MUI components
import Stack from '@mui/material/Stack';
import { Icon } from '@mui/material';

dayjs.extend(utc);
dayjs.extend(timezone);

// Declaring props types for DefaultCell
interface Props {
    timestamp: string;
    row: any;
}

type BusinessDaysOptions = {
    holidays: string[];
    holidayFormat: string;
};

function DateCell({ timestamp, row }: Props): JSX.Element {
    // const displayDate = dayjs(timestamp).tz('Japan').format('YYYY-MM-DD\xa0\xa0h:mm A');
    const displayDate = dayjs(timestamp).format('YYYY-MM-DD\xa0\xa0h:mm A');

    const options: BusinessDaysOptions = {
        holidays: [],
        holidayFormat: 'YYYY-MM-DD'
    };

    row.org.country.holidays.forEach((holiday: any) => {
        options.holidays.push(holiday.date);
    });

    dayjs.extend(dayjsBusinessDays, options);
    const dueDate = dayjs(row.shippingDueDate);
    const daysRemaining = dueDate.diff(dayjs(), 'day');

    const getDueDateString = () => {
        switch (row.state) {
            case 'New':
            case 'Accepted':
                if (daysRemaining < 0) {
                    return (
                        <MDTypography variant="caption" fontWeight="regular" color="error">
                            <Icon>warning</Icon>&nbsp; {daysRemaining * -1}{' '}
                            {daysRemaining === -1 ? 'day' : 'days'} past due
                        </MDTypography>
                    );
                }
                if (daysRemaining === 0) {
                    return (
                        <MDTypography variant="caption" fontWeight="regular" color="warning">
                            <Icon>warning</Icon>&nbsp; Must ship today
                        </MDTypography>
                    );
                }
                if (daysRemaining > 0) {
                    return (
                        <MDTypography variant="caption" fontWeight="regular" color="info">
                            {daysRemaining} {daysRemaining === 1 ? 'day' : 'days'} remaining
                        </MDTypography>
                    );
                }
                break;
            default:
                return <div />;
        }
        return <div />;
    };

    return (
        <Stack>
            <MDTypography variant="caption" fontWeight="medium" color="text">
                {displayDate}
            </MDTypography>
            {getDueDateString()}
        </Stack>
    );
}

// Declaring default props for DefaultCell
DateCell.defaultProps = {};

export default DateCell;
